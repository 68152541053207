.addComp {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	> *:not(:last-child) {
		margin-right: 10px;
	}
}

.components {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	> div {
		padding: 10px;
		border-radius: 4px;
		border: 1px
			solid
			var(
				--text-50
			);
		position: relative;
		&:not(:last-child) {
			margin-right: 10px;
		}
		.remove {
			display: none;
			position: absolute;
			top: -10px;
			right: -10px;
			padding: 3px;
			border-radius: 50%;
			width: 25px;
			height: 25px;
			text-align: center;
			background-color: var(
				--bg-alt-2
			);
			&:hover {
				cursor: pointer;
			}
		}
		&:hover {
			.remove {
				display: block;
				color: red;
			}
		}
	}
}
