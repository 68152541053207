.header {
    background-color: var(--card-bg);
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--border);
    position: sticky;
    top: 0;
    z-index: 10;
}

.dropDown {
    transition: height 100ms;
    &:hover {
        cursor: pointer;
        background-color: var(--bg-alt-1);
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }
        .dropdown-content {
            display: flex;
            height: 100%;
        }
        >div>span {
            transform: translateY(0px);
        }
    }
    position: relative;
    border-radius: 6px 6px 0 0;
    >div {
        padding: 10px;
        display: flex;
        justify-content: center;
        >span {
            transition: all 100ms;
            transform: translateY(-2px);
        }
    }
    .dropdown-content {
        border-radius: 0 0 6px 6px;
        height: 0%;
        display: none;
        position: absolute;
        background-color: var(--bg-alt-1);
        z-index: 1;
        width: 100%;
        flex-direction: column;
        padding: 10px;
    }
}