.addWastage {
	display: flex;
	flex-flow: column nowrap;
	.line {
		display: flex;
		flex-flow: row nowrap;
		> *:not(:last-child) {
			margin-right: 10px;
		}
	}
	.submitBtn {
		margin: 10px;
		max-width: 100px;
		align-self: end;
	}
}
