td a {
    color: var(--p800);
    &:hover {
        color: var(--p900);
        text-decoration: underline;
    }
}

.detailTableBody {
    margin-bottom: 40px;
}