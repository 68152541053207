.items {
    display: flex;
    flex-direction: column;
    >div {
        display: flex;
        flex-direction: row;
        margin: 10px;
    }
    .info {
        margin-right: 30px;
    }
    .subItems {
        display: flex;
        flex-flow: row wrap;
        >div {
            margin: 0px 10px 10px 10px;
            hr {
                margin: 3px 0;
            }
            p {
                padding: 0 15px 0 5px;
            }
        }
    }
}

.tag {
    color: white;
    line-height: 20px;
    text-decoration: none;
    padding: 0;
    font-size: 13px;
    position: relative;
    margin-right: 3px;
    margin-left: 3px;
    white-space: nowrap;
    display: inline-block;
    width: 7px;
    height: 17px;
    margin-bottom: -4px;
    border-radius: 13px;
}

.rowNegative {
    background-color: #ed323269;
}