[class*="badge-"] {
    padding: 2px 4px;
    border-radius: 6px;
    margin: 3px;
}

.badge-awaiting-payment {
    background: #000000;
    color: #fff;
}

.badge-awaiting-shipment {
    background: #933d9a;
    color: #fff;
}

.badge-on-hold {
    background: #65100b;
    color: #fff;
}

.badge-needs-artwork {
    background: #ff0000;
    color: #fff;
}

.badge-awaiting-approval {
    background: #ff9900;
    color: #fff;
}

.badge-rework-requested {
    background: #c7ada1;
    color: #fff;
}

.badge-approved {
    background: #006600;
    color: #fff;
}

.badge-ready-to-print {
    background: #00ff00;
    color: #000000;
}

.badge-production-ready {
    background: #00ffff;
    color: #000000;
}

.badge-refund-requested {
    background: #666666;
    color: #fff;
}

.badge-cancelled {
    background: #000000;
    color: #fff;
}

.badge-shipped {
    background: #ffcc00;
    color: #fff;
}

.badge-pick-up {
    background: #ffc0cb;
    color: #fff;
}

.badge-awaiting-pick-up {
    background: #ff00cc;
    color: #fff;
}

.badge-in-production {
    background-color: #3995c3;
    color: white;
} 

.badge-pre-production-ready {
    background: #C8A2C8;
    color: white;
}