.sidebar {
    flex-basis: 20%;
    background-color: var(--card-bg);
    overflow: hidden;
    &.collapsed {
        flex-basis: 0;
        width: 0%;
    }
    transition: all 300ms;
    position: sticky;
    top: 78px;
    z-index: 1;
}

.sideNav {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-right: var(--border);
    .navFooter {
        position: fixed;
        bottom: 0px;
        padding: 15px;
        &:hover {
            cursor: pointer;
        }
    }
}

@media (max-width: 768px) {
    .hamburger {
        display: inline-block;
    }
    .sidebar {
        position: fixed;
        top: unset;
        width: 100%;
        height: 100%;
    }
}

.transition,
p.card-text,
ul.accordion>li i.arrow:before,
ul.accordion>li i.arrow:after {
    transition: all 0.25s ease-in-out;
}

ul.dashed {
    list-style-type: none;
    li {
        padding: 10px;
    }
}

.no-select,
h2 {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.infoHeader {
    display: flex;
    margin: 0;
    cursor: pointer;
    flex-basis: 80%;
    align-items: center;
    padding: 10px;
}

ul.accordion>li>div {
    position: relative;
    overflow: hidden;
    opacity: 1;
    transform: translate(0, 0);
    background-color: var(--bg-alt-2);
    z-index: 2;
    transition: all 0.2s ease-in-out;
    flex-basis: 100%;
}

ul.accordion {
    list-style: none;
    perspective: 900;
    padding: 0;
    margin: 0;
}

ul.accordion li {
    position: relative;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    &:hover {
        color: var(--p800);
    }
    .fa {
        padding-right: 5px;
    }
}

a.navActive li {
    color: white;
    background-color: var(--s900);
}

ul.accordion>li i.arrow {
    transform: translate(-6px, 0);
    margin-top: -8px;
    flex-basis: 20%;
}

ul.accordion>li i.arrow:before,
ul.accordion>li i.arrow:after {
    content: "";
    position: absolute;
    background-color: var(--p800);
    width: 3px;
    height: 9px;
}

ul.accordion>li i.arrow:before {
    transform: translate(-2px, 0) rotate(45deg);
}

ul.accordion>li i.arrow:after {
    transform: translate(2px, 0) rotate(-45deg);
}

ul.accordion>li input[type=checkbox] {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
}

ul.accordion>li input[type=checkbox]:checked~div {
    margin-top: 0;
    max-height: 0;
    opacity: 0;
}

ul.accordion>li input[type=checkbox]:checked~i:before {
    transform: translate(2px, 0) rotate(45deg);
}

ul.accordion>li input[type=checkbox]:checked~i:after {
    transform: translate(-2px, 0) rotate(-45deg);
}