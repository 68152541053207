.detailForm {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    >* {
        margin: 5px;
    }
    div {
        &:first-of-type>*:not(:last-child) {
            margin-right: 10px;
        }
    }
    .orderItem {
        display: flex;
        align-items: center;
        >*:not(:last-child) {
            margin-right: 10px;
        }
    }
}

table.pTable td,
table.pTable th,
table.pTable td,
table.pTable th {
    border: 1px solid #000;
    text-align: center;
    padding: 2px;
}

table.pTable {
    border-collapse: collapse;
}

.orderHidden {
    visibility: hidden;
    position: absolute;
    top: 0;
    z-index: -10;
}

#purchaseOrderPdf {
    display: block;
    width: 100%;
    min-height: 500px;
    margin-top: 10px;
}