.progressBox {
    width: 100%;
    border: 1px solid var(--text-50);
    height: 26px;
    overflow: hidden;
    position: relative;
    border-radius: 2px;
    .boxText {
        width: 100%;
        display: flex;
        position: relative;
        line-height: 24px;
        justify-content: center;
        z-index: 1;
    }
    .boxBack {
        background-color: green;
        height: 100%;
        position: absolute;
        top: 0;
    }
}

hr {
    margin: 10px 0px;
}

.newItem {
    display: flex;
    align-items: center;
    >*:not(:last-child) {
        margin-right: 10px;
    }
}