.loginCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 40%;
    max-width: 400px;
    form {
        width: 100%;
    }
    .MuiFormControl-root {
        margin-bottom: 15px;
    }
    button {
        float: right;
    }
}

.loginContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    // filter: blur(8px);
    // -webkit-filter: blur(8px);
    background-color: var(--bg);
    z-index: 11;
}

.slide-out {
    animation-name: slideOut;
    animation-duration: 1s;
}

@keyframes slideOut {
    to {
        transform: translateY(100vh);
    }
}