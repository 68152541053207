.itemHeader {
    display: flex;
    justify-content: space-between;
}

.stockWarning {
    background-color: #ed323269;
}

.itemForm {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    >* {
        flex-basis: 30%;
        margin: 15px 5px !important;
    }
}