@import "./assets/styles/themes";
@import "./assets/styles/badges.scss";
:root {
    @each $key,
    $value in $primary {
        --#{$key}: #{$value};
    }
    @each $key,
    $value in $secondary {
        --#{$key}: #{$value};
    }
}

@each $key,
$value in $themes {
    .#{$key} {
        @each $key2,
        $value2 in $value {
            --#{$key2}: #{$value2};
        }
    }
}

body {
    background-color: var( --bg);
    color: var( --text);
}

#root {
    // overflow: hidden;
    >div:not(.notification-container) {
        // height: 100vh;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        .header {
            flex: 0 1 auto;
        }
        .container {
            flex: 1 1 auto;
        }
    }
}

.container {
    display: flex;
    flex-direction: row !important;
}

.sideNav a {
    text-decoration: none;
    color: var( --text);
    &:hover {
        text-decoration: none;
    }
}

a {
    color: var( --p800);
    &:hover {
        color: var( --p900);
        text-decoration: underline;
    }
}

.main {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    width: 100%;
    // padding: 20px;
    overflow-y: auto;
    .card:first-child {
        margin-top: 20px;
    }
}

.card {
    box-shadow: 0 2px 10px rgba( 0, 0, 0, 0.24);
    transition: all 300ms;
    border-radius: 6px;
    padding: 15px;
    width: 90%;
    margin: 0 15px 10px;
    background-color: var( --card-bg);
    flex: 1;
    &:hover {
        box-shadow: 0 2px 15px rgba( 29, 29, 29, 0.5);
    }
}

// borgar
.hamburger {
    padding: 15px 15px;
    display: none;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    scale: 0.8;
}

.hamburger:hover {
    opacity: 0.7;
}

.hamburger.is-active:hover {
    opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: var( --text-50);
}

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: var( --text-50);
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -10px;
}

.hamburger-inner::after {
    bottom: -10px;
}

.hamburger--spin .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier( 0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier( 0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
    transform: rotate( 225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier( 0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate( -90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier( 0.215, 0.61, 0.355, 1);
}

.positive {
    color: green;
}

.negative {
    color: red;
}

.warning {
    color: orange;
}

.chips {
    display: flex;
    >* {
        margin: auto 5px !important;
    }
}

td a {
    color: var( --p800);
    &:hover {
        color: var( --p900);
        text-decoration: underline;
    }
}

.dangerBtn {
    color: $warning !important;
    &.MuiButton-outlined {
        border: 1px solid $warning;
    }
    &:hover {
        background-color: unquote( $warning + "14") !important;
    }
}

table .no-wrap {
    white-space: nowrap;
}