.notification-container {
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999999
}

.bottom-right {
    bottom: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.notification {
    transition: .3s ease;
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin: 0 0 6px;
    padding: 10px;
    margin-bottom: 15px;
    // width: 300px;
    border-radius: 3px 3px 3px 3px;
    // box-shadow: 0 0 10px #999;
    color: #fff;
    >span {
        margin-right: 15px;
    }
}

.notification-title {
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 6px;
    width: 300px;
    height: 18px;
}

.notification-message {
    margin: 0;
    text-align: left;
    height: 18px;
    margin-left: -1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notification-container button {
    font-weight: 700;
    color: #fff;
    outline: none;
    border: none;
    line-height: 1;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
}

.fadeout {
    -webkit-animation: fadeout .2s linear forwards;
    animation: fadeout .2s linear forwards;
}

@-webkit-keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}