.homeStats {
	display: flex;
	flex-flow: column
		wrap;
	> div {
		display: flex;
		flex-flow: row wrap;
		> div {
			canvas {
				max-height: 200px;
			}
			position: relative;
			margin: 20px;
		}
	}
	.doughnutPercent {
		position: absolute;
		top: 62%;
		left: 50%;
		transform: translate(
			-50%,
			-50%
		);
		font-size: 100%;
		font-weight: bold;
	}
	.lowStockItems {
		> li {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			margin-top: 5px;
			padding: 2px 0px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.219);
		}
	}
}
